import Vue from "vue";
import LoadScript from "vue-plugin-load-script";
import VueClipboard from "vue-clipboard2";
import App from "./App.vue";
import router from "./router";

Vue.use(LoadScript);
Vue.use(VueClipboard);

Vue.config.productionTip = false;

new Vue({
   router,
   render: (h) => h(App),
}).$mount("#app");
