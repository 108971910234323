<template>
  <div class="mail">
    <header class="masthead bg-secondary text-white text-center" id="home">
      <div class="container">
        <div id="recaptchaPanel">
          <h1 class="font-weight-normal">
            Please click below check box to reveal the email address
          </h1>
          <p class="lead"></p>

          <div class="d-flex justify-content-center">
            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              :sitekey="sitekey"
            ></vue-recaptcha>
          </div>
          <br />
          <div v-if="loading">Loading...</div>
        </div>

        <div id="resultMailPanel" v-show="resultPanel">
          <h3 v-if="success" class="pb-4 font-weight-normal">
            Yaas 🚀 This is the email address you are looking for
          </h3>
          <h3 v-else class="pb-4 text-danger font-weight-normal">
            Oops, we couldn't find the email address you were looking for
          </h3>
          <div
            id="result-input"
            class="overlap input-group pb-4 pt-3 w-75 mx-auto"
            v-if="success"
          >
            <div class="input-group-prepend">
              <a
                v-bind:href="result.mailto"
                role="button"
                id="button-addon1"
                class="btn btn-info"
              >
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  class="bi bi-envelope"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
                  />
                </svg>
              </a>
            </div>
            <input
              type="text"
              readonly="readonly"
              id="email"
              class="form-control form-control-lg result-input"
              v-bind:value="result.emailAddress"
            />
            <div class="input-group-append">
              <button
                v-on:click="copyEmail"
                type="button"
                class="btn btn-primary"
              >
                COPY
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
const API_URL = "https://hide-mail.herokuapp.com/api/email/";

export default {
  name: "Mail",
  components: { VueRecaptcha },
  data: () => {
    return {
      slug: "",
      loading: false,
      recaptchaPanel: true,
      resultPanel: false,
      result: {
        mailto: "",
        emailAddress: "",
      },
      success: false,
      sitekey: process.env.VUE_APP_SITE_KEY,
    };
  },
  methods: {
    onCaptchaVerified: function (recaptchaToken) {
      const url = API_URL + this.slug;
      this.loading = true;
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          id: this.slug,
          "g-recaptcha-response": recaptchaToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data == null) {
            this.loading = false;
            this.recaptchaPanel = false;
            this.resultPanel = true;
            this.success = false;
          } else {
            this.loading = false;
            const mailto = "mailto:" + result.data.email;
            this.result.mailto = mailto;
            this.result.emailAddress = result.data.email;
            this.recaptchaPanel = false;
            $("#recaptchaPanel").fadeOut();
            setTimeout(() => {
              this.resultPanel = true;
              $("#resultMailPanel").fadeIn(400);
              this.success = true;
            }, 700);
          }
        })
        .catch((error) => {
          this.recaptchaPanel = false;
          this.resultPanel = true;
          this.success = false;
        });
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    copyEmail() {
      let emailCopy = document.querySelector("#email");
      emailCopy.setAttribute("type", "text");
      emailCopy.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        alert("Whoops! unable to copy");
      }
    },
  },
  mounted: function () {
    this.slug = this.$route.params.id;
  },
};
</script>

<style scoped>
.mail {
  height: 700px;
}

#home {
  height: 100%;
}
</style>
