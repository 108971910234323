<template>
  <div id="app">
    <!-- NAVBAR -->
    <nav class="navbar navbar-expand-lg bg-secondary fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand js-scroll-trigger" href="/"> hidEmail </a>
        <button
          class="navbar-toggler navbar-toggler-right font-weight-bold bg-primary text-white rounded"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item mx-0 mx-lg-1">
              <a
                class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                href="#home"
              >
                Home
              </a>
            </li>
            <li class="nav-item mx-0 mx-lg-1">
              <a
                class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                href="#about"
              >
                About
              </a>
            </li>
            <li class="nav-item mx-0 mx-lg-1">
              <a
                class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                href="#contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <router-view />

    <section class="page-section bg-primary text-white mb-0" id="about">
      <div class="container">
        <!-- About Section Heading-->
        <div class="text-center">
          <h2 class="page-section-heading d-inline-block text-white">ABOUT</h2>
        </div>
        <!-- Icon Divider-->
        <div class="divider-custom divider-light">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fas fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <!-- About Section Content-->
        <div class="row text-center">
          <div class="col-lg-4">
            <p class="lead">
              Exposing email addresses on the internet can be dangerous.
              Hidemail shows email addresses to humans only 😉
            </p>
          </div>
          <div class="col-lg-4">
            <p class="lead">
              Powered by Google reCAPTCHA v2, an effective solution to prevent
              robots 🤖
            </p>
          </div>
          <div class="col-lg-4">
            <p class="lead">
              It's free! This service is free for all as long as possible.
              <br />
              Br. Adityacprtm 👨‍🚀
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section" id="contact">
      <div class="container">
        <!-- Contact Section Heading-->
        <div class="text-center">
          <h2 class="page-section-heading text-secondary d-inline-block mb-0">
            CONTACT
          </h2>
        </div>
        <!-- Icon Divider-->
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fas fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <!-- Contact Section Content-->
        <div class="row justify-content-center">
          <!-- <div class="col-lg-4">
                  <div class="d-flex flex-column align-items-center">
                     <div class="icon-contact mb-3">
                        <i class="fas fa-mobile-alt"></i>
                     </div>
                     <div class="text-muted">Phone</div>
                     <div class="lead font-weight-bold">(62) 555-5555</div>
                  </div>
               </div> -->
          <div class="col-lg-4">
            <div class="d-flex flex-column align-items-center">
              <div class="icon-contact mb-3">
                <i class="far fa-envelope"></i>
              </div>
              <div class="text-muted">Email</div>
              <a
                class="lead font-weight-bold"
                href="https://hidemail.adityacprtm.dev/m/pxCYO"
                onclick="hidemailpopup=window.open('https://hidemail.adityacprtm.dev/m/pxCYO','hidemailpopup','width=580,height=635'); return false;"
              >
                p......@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="copyright py-4 text-center text-white">
      <div class="container">
        <p class="lead">
          hidEmail by
          <a
            href="http://adityacprtm.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            Adityacprtm
          </a>
          🥂
        </p>
      </div>
    </section>

    <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes)-->
    <!-- <div class="scroll-to-top d-lg-none position-fixed">
         <a
            class="js-scroll-trigger d-block text-center text-white rounded"
            href="#page-top"
            ><i class="fa fa-chevron-up"></i
         ></a>
      </div> -->
  </div>
</template>

<script>
export default {
  name: "App",
  mounted: function () {
    this.$loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"
    );
    this.$loadScript(
      "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.bundle.min.js"
    );
    this.$loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"
    );
    this.$loadScript("/js/scripts.js");
  },
};
</script>

<style></style>
