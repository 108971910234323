<template>
  <div class="about">
    <section class="page-section" id="home">
      <div class="container">
        <!-- Contact Section Heading-->
        <div class="text-center mt-5">
          <h2 class="page-section-heading text-secondary d-inline-block mb-0">
            Ooops! Page NotFound
          </h2>
        </div>

        <!-- Contact Section Content-->
        <div class="row justify-content-center mt-3">
          <img
            src="@/assets/404.png"
            style="max-width: 50%; max-height: 50%"
            alt=""
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style></style>
