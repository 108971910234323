<template>
  <div class="home">
    <header class="masthead bg-secondary text-white text-center" id="home">
      <div class="container">
        <form
          id="formPanel"
          v-show="formPanel"
          class="pt-3"
          @submit.prevent="onSubmit"
        >
          <div class="form-group">
            <h1 class="text-center pb-4">
              Protects Your Email Address <br />from Internet Robot Spiders.
              Goodbye Spam!
            </h1>
            <div id="input-group" class="input-group mx-auto">
              <input
                v-model="emailAddress"
                type="email"
                name="email"
                id="emailAddr"
                placeholder="Enter email address"
                required="required"
                class="form-control form-control-lg mr-1"
                v-bind:readonly="emailReadonly"
              />
              &nbsp; &nbsp;
              <button
                v-on:keyup.enter="submit"
                type="submit"
                class="btn btn-primary"
                v-bind:disabled="submitButton.isDisabled"
              >
                <svg
                  class="svg-inline--fa fa-shield-alt fa-w-16"
                  aria-hidden="true"
                  data-prefix="fas"
                  data-icon="shield-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M496 128c0 221.282-135.934 344.645-221.539 380.308a48 48 0 0 1-36.923 0C130.495 463.713 16 326.487 16 128a48 48 0 0 1 29.539-44.308l192-80a48 48 0 0 1 36.923 0l192 80A48 48 0 0 1 496 128zM256 446.313l.066.034c93.735-46.689 172.497-156.308 175.817-307.729L256 65.333v380.98z"
                  ></path>
                </svg>
                Hide Me
              </button>

              <div class="alert-input invalid-feedback">
                Please enter a valid email address.
              </div>
            </div>
          </div>

          <div
            v-show="recaptchaPanel"
            id="recaptchaPanel"
            class="form-group recaptchaPanel"
          >
            <p class="lead pt-3">
              Almost there, please complete below verification
            </p>

            <!-- Vue x Google Recaptcha -->
            <div class="d-flex justify-content-center">
              <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                :sitekey="sitekey"
              ></vue-recaptcha>
            </div>
            <br />
            <div v-if="loading" class="loading">Loading...</div>
          </div>
        </form>

        <div
          id="resultPanel"
          v-show="resultPanel"
          class="form-group text-left p-5"
        >
          <h1 style="color: #00cfd5">Woohoo! 🚀</h1>
          <p class="lead pt-1">
            The armed url to reveal your email address
            <strong>{{ emailAddress }}</strong> is generated, just place it in
            your website or wherever you`d like share
          </p>
          <div class="form-group pt-1 pb-2">
            <div class="form-group" style="max-width: 500px">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <svg
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 16 16"
                      class="bi bi-link-45deg"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.715 6.542L3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.001 1.001 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"
                      />
                      <path
                        d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 0 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 0 0-4.243-4.243L6.586 4.672z"
                      />
                    </svg>
                  </span>
                </div>
                <input
                  type="text"
                  readonly="readonly"
                  class="form-control result-input"
                  v-bind:value="result"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-clipboard:copy="result"
                  >
                    COPY
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <h4 class="weight-400">
            Example 1
            <small class="text-muted">open in new tab window </small>
          </h4>
          <div class="example-block">
            <p class="pl-4 pt-4" v-html="exampleOne"></p>
            <div class="bd-clipboard">
              <button
                title="Copy"
                class="btn btn-clipboard copy"
                v-clipboard:copy="exampleOne"
              >
                <svg
                  class="svg-inline--fa fa-copy fa-w-14"
                  aria-hidden="true"
                  data-prefix="far"
                  data-icon="copy"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"
                  ></path>
                </svg>
              </button>
            </div>
            <figure>
              <pre> <code>{{ exampleOne }}</code> </pre>
            </figure>
          </div>

          <hr />
          <h4 class="weight-400">
            Example 2
            <small class="text-muted">open in a Pop-up window</small>
          </h4>
          <div class="example-block">
            <p class="pl-4 pt-4" v-html="exampleTwo"></p>
            <div class="bd-clipboard">
              <button
                title="Copy"
                class="btn btn-clipboard copy"
                v-clipboard:copy="exampleTwo"
              >
                <svg
                  class="svg-inline--fa fa-copy fa-w-14"
                  aria-hidden="true"
                  data-prefix="far"
                  data-icon="copy"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"
                  ></path>
                </svg>
              </button>
            </div>
            <figure>
              <pre> <code>{{ exampleTwo }}</code> </pre>
            </figure>
          </div>
          <hr />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
// @ is an alias to /src
import VueRecaptcha from "vue-recaptcha";
import Styles from "@/assets/css/styles.css";
import Heading from "@/assets/css/heading.css";
import Body from "@/assets/css/body.css";
const API_URL = "https://hide-mail.herokuapp.com/api/email";

export default {
  name: "Home",
  components: { VueRecaptcha },
  data: () => {
    return {
      emailReadonly: false,
      emailAddress: "",
      submitButton: {
        label: "Hide Me",
        isDisabled: false,
      },
      errorMessage: "",
      result: "",
      host: location.origin,
      recaptchaPanel: false,
      resultPanel: false,
      formPanel: true,
      shortUrl: "",
      sitekey: process.env.VUE_APP_SITE_KEY,
      exampleOne: "",
      exampleTwo: "",
      loading: false,
    };
  },
  watch: {
    emailAddress: {
      handler() {
        this.errorMessage = "";
      },
    },
    deep: true,
  },
  methods: {
    onSubmit() {
      if (!this.validateEmail(this.emailAddress))
        return $("#email").addClass("is-invalid");
      $("#email").removeClass("is-invalid");
      this.recaptchaPanel = true;
      $("#recaptchaPanel").slideDown(600);
      this.submitButton.isDisabled = true;
      this.emailReadonly = true;
    },
    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.loading = true;
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify({
          email: this.emailAddress,
          "g-recaptcha-response": recaptchaToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "error") {
            this.loading = false;
            this.errorMessage = result.message;
          } else {
            this.loading = false;
            $("#email").removeClass("is-invalid");
            this.$refs.recaptcha.reset();
            this.result = location.origin + "/m/" + result.slug;
            this.setExample(this.result);
            $("#recaptchaPanel").slideUp(700);
            this.resultPanel = true;
            $("#resultPanel").slideUp(700);
            this.formPanel = false;
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    setExample(result) {
      this.exampleOne = `<a target="_blank" href="${result}">a......@mail.com</a>`;
      this.exampleTwo = `<a href="${result}" onclick="hidemailpopup=window.open('${result}','hidemailpopup','width=580,height=635'); return false;">a......@mail.com</a>`;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    copyEmail(id) {
      let emailCopy = document.querySelector("#" + id);
      emailCopy.setAttribute("type", "text");
      emailCopy.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        alert("Whoops! unable to copy");
      }
    },
  },
};
</script>
